if (typeof window.hello !== 'undefined') {
    window.hello.init({
        facebook: fbId,
        twitter: twId
    }, {
        'display': 'popup',
        'redirect_uri': '/user/twitter',
        oauth_proxy: 'https://auth-server.herokuapp.com/proxy',
        scope: "email,"
    });
}

function isLoginPage() {
    return window.location.href.indexOf('user/login') > -1;
}

$(function () {
    $('.btn-login, .js-btn-login, .js-login-b2c').off('click').on('click', function (e) {
        e.preventDefault();
        e.stopPropagation();
        System.hideErrors();
        var email = this.form.email.value;
        var password = this.form.password.value;
        var referer = $("input[name='referer']").val();
        var usertype = $(this).attr('data-usertype');
        //        var remember = this.form.remember.checked ? 1 : 0;

        validateEmail(this.form.email);

        var me = this;
        var isBuy = $(this).data('isbuy');
        var isObt = $(this).data('obt');
        var social = $(this).data('social');
        var l = Ladda.create(this);
        l.start();
        Auth.login(email, password, function (d) {

            MA_LoginSuccess(e, usertype);

            if (isBuy) {
                cart[isBuy](me, l);
                Auth.initPage();
            } else if (isObt) {
                l.stop();
                me.form.email.value = '';
                me.form.password.value = '';
                Auth.addTestBooks();
            } else {
                if (d.hash != undefined) {
                    var hash = d.hash;
                }
                if (!isLoginPage()) {
                    return;
                }
                if (referer && !hash) {
                    location.href = referer;
                } else {
                    location.href = hash;
                }
            }
        }, function (error, code) {

            l.stop();
            // console.log(ERROR_INCORRECT_EMAIL_OR_PASSWORD, code);

            if (code == '1000') {
                $('.loginform .email-input').removeClass('success').addClass('error');
            }
            if (code != ERROR_INCORRECT_EMAIL_OR_PASSWORD) {
                System.showErrors(error);
            } else {
                var forgot = parseInt(Cookies.get('forgot_password'));
                if (forgot && forgot < 5) {
                    Cookies.set('forgot_password', forgot + 1, { path: '' });
                    System.showErrors(error);
                } else if (forgot > 4) {
                    System.showErrors(locale['TEXT_INCORRECT_EMAIL_OR_PASSWORD'] + ' <a href="#restore-password" onClick="System.hideErrors();$(\'.restore input[name=email]\').val(\'' + email + '\'); forgot_reg();">Forgot password?</a>');
                } else {
                    Cookies.set('forgot_password', 1, { path: '' });
                    System.showErrors(error);
                }
            }

        });
    });

    $('.btn-login-subscribe').on('click', function (e) {
        e.preventDefault();

        System.hideErrors();

        var email = this.form.email.value;
        var password = this.form.password.value;
        var usertype = $(this).attr('data-usertype');

        validateEmail(this.form.email);

        var me = this;
        var l = Ladda.create(this);
        l.start();

        Auth.login(email, password, function (d) {
            MA_LoginSuccess(e, usertype);
            $('#channel_subscribe_method').submit();
        }, function (error, code) {
            l.stop();
            // console.log(ERROR_INCORRECT_EMAIL_OR_PASSWORD, code);
            if (code == '1000') {
                $('.loginform .email-input').removeClass('success').addClass('error');
            }
            if (code != ERROR_INCORRECT_EMAIL_OR_PASSWORD) {
                System.showErrors(error);
            } else {
                var forgot = parseInt(Cookies.get('forgot_password'));
                if (forgot && forgot < 5) {
                    Cookies.set('forgot_password', forgot + 1, { path: '' });
                    System.showErrors(error);
                } else if (forgot > 4) {
                    System.showErrors(locale['TEXT_INCORRECT_EMAIL_OR_PASSWORD'] + ' <a href="#restore-password" onClick="System.hideErrors();$(\'.restore input[name=email]\').val(\'' + email + '\'); forgot_reg();">Forgot password?</a>');
                } else {
                    Cookies.set('forgot_password', 1, { path: '' });
                    System.showErrors(error);
                }
            }

        });
    });

    $('.signup-btn-subscribe').off('click').on('click', function (e) {
        e.preventDefault();
        var email = this.form.email.value;
        var password = this.form.password.value;

        var me = this;
        var l = Ladda.create(this);
        l.start();

        Auth.checkEmail(email, function (r, msg) {
            if (!r) {
                l.stop();
                System.showErrors(msg + ' <a href="#restore-password" onClick="System.hideErrors();$(\'.restore input[name=email]\').val(\'' + email + '\'); forgot_reg();">Forgot password?</a>');
            } else {
                Auth.register(email, password, null, function (result, message) {
                    if (result) {
                        $('#channel_subscribe_method').submit();
                    } else {
                        System.showErrors(message);
                        $('.joinform .email-input').removeClass('success').addClass('error');
                        l.stop();
                    }
                });
            }
        }, false);
    });

    $('.facebook, js-login-on-facebook').on('click', function (e) {
        e.preventDefault();
        var l = Ladda.create(this);
        l.start();
        var me = this;
        var isVote = $(this).data('isvote');
        var isFollow = $(this).data('isfollow');
        var channelId = $(this).data('channel');
        var isBuy = $(this).data('isbuy');
        var isObt = $(this).data('obt');
        var usertype = $(this).attr('data-usertype');
        var isB2b = usertype === 'b2b' ? true : null;
        var page = $('#page').val();

        var scope = 'email,public_profile,user_link';

        hello.login('facebook', { scope: scope }, function (resp) {
           // console.log('hello.login(facebook', resp);
            if (resp && resp.error) {
                l.stop();
                if (resp && resp.error) {
                    if (resp.error.code === 'access_denied') {
                        System.showErrors('Login has been cancelled');
                    } else {
                        System.showErrors(resp.error.message);
                    }
                    return;
                }
                return;
            }
            //            console.log(resp.authResponse.access_token);
            //            l.stop();
            //            return;
            Auth.fbLogin(resp.authResponse.access_token, function (r, data, msg) {
                if (r) {
                    MA_LoginFacebookEvent(e, data.isB2b, data.isNew);

                    if (data.isNew) {
                        MA_JoinSuccess(e, usertype);
                        // MA_JoinSuccessFBPixel(e, usertype);
                    } else {
                        MA_LoginSuccess(e, usertype);
                    }

                    l.start();
                    if (isBuy) {
                        cart[isBuy](me, l);
                        Auth.initPage();
                        $('.user-auth-not-identity').hide();
                    } else if (isObt) {
                        l.stop();
                        Auth.addTestBooks();
                    } else if (page == 'channel_subscribe') {
                        $('#channel_subscribe_method').submit();
                    } else if (isVote == 1) {
                        Auth.initPage();
                        $('.user-auth-not-identity').hide();
                        $('.vote-btn').click();
                        $('.c-popup').fadeOut();
                    } else if (isFollow == 1) {
                        Auth.initPage();
                        $('.user-auth-not-identity').hide();
                        $('.js-follow-channel[data-channel="' + channelId + '"]').click();
                        $('.c-popup').fadeOut();
                    } else if (data.isNew || !data.oldState) {
                        if (data.state == false) {
                            window.location = "/user/profile";
                        } else {
                            RedirectHelper.setAction('System.notify("Your account has been successfully activated!");');
                            window.location = Config.BASE_PATH + "/store";
                        }
                    } else {
                        window.location = data.hash ? data.hash : "/user/profile";
                    }
                } else {
                    l.stop();
                    System.showErrors(msg);
                }
            }, isB2b)
        });
    });

    var startApp = function () {
        /*        gapi.load('auth2', function () {
                    // Retrieve the singleton for the GoogleAuth library and set up the client.
                    auth2 = gapi.auth2.init({
                        client_id: google_client_id,
                        cookiepolicy: 'single_host_origin',
                        // Request scopes in addition to 'profile' and 'email'
                        scope: 'email' // https://www.googleapis.com/auth/userinfo.profile
                    });
        //            var bLogin = document.getElementsByClassName('google-button-login');
                    var elements = $('.google-button-login, .google-button-sign, .js-login-on-google-plus, .js-login-from-goole'); // document.getElementsByClassName('google-button-sign');
                    if(elements.length>0){
                        attachSignin(elements);

                    }
                });*/
        var googleTokenClient = {};
        var loaderForGoogleButton = '';

        window.onload = function () {
            // Retrieve the singleton for the GoogleAuth library and set up the client.
            googleTokenClient = google.accounts.oauth2.initTokenClient({
                client_id: google_client_id,
                prompt: 'select_account',
                //cookiepolicy: 'single_host_origin',
                /*callback: (tokenResponse) => {
                    var element = $('.js-login-from-goole');
                    var isBuy = $(element).data('isbuy');
                    var isObt = $(element).data('obt');
                    var usertype = $(element).attr('data-usertype');
                    var isB2b = usertype === 'b2b' ? true : null;
                    var isStandardForm = $(element).data('ispopup');
                    var needsToBeReloaded = $('.login-main-page').length || isB2b;
                    var page = $('#page').val();

                    Auth.googleLogin(tokenResponse.access_token, function (r, data, msg) {
                        console.log('usertype ' + usertype);
                        if (r) {
                            MA_LoginGoogleEvent(element, data.isB2b, data.isNew);

                            if(data.isNew){
                                MA_JoinSuccess(element, usertype);
                                // MA_JoinSuccessFBPixel(element, usertype);
                            } else {
                                MA_LoginSuccess(element, usertype);
                            }
                            var isVote = $('.google-button-login').data('isvote');
                            var isFollow = $('.google-button-login').data('isfollow');
                            var channelId = $('.google-button-login').data('channel');
                            if(isVote == 1) {
                                Auth.initPage();
                                $('.vote-btn').click();
                                $('.c-popup').fadeOut();
                                return true;
                            }
                            if(isFollow == 1) {
                                Auth.initPage();
                                $('.js-follow-channel[data-channel="'+channelId+'"]').click();
                                $('.c-popup').fadeOut();
                                return true;
                            }

                            if(isBuy){
                                cart[isBuy](element, loaderForGoogleButton);
                                Auth.initPage();
                            } else if(isObt) {
                                loaderForGoogleButton.stop();
                                Auth.addTestBooks();
                            } else if (page == 'channel_subscribe') {
                                $('#channel_subscribe_method').submit();
                            } else if (data.isNew) {
                                RedirectHelper.setAction('System.notify("Your account has been successfully activated!");');
                                location.href = Config.BASE_PATH + "/store";
                            }else if (isStandardForm) {
                                if(usertype === 'b2b' && data.isNew){
                                    MA_JoinSuccess(event, 'b2b');
                                }
                                $(".join-window").hide();
                                if (needsToBeReloaded) document.location.reload();
                                else Auth.initPage();
                            } else {
                                if(data.isB2b) {
                                    location.href = data.hash ? data.hash :"/user/projects";
                                } else {
                                    location.href = "/user/profile";
                                }
                            }
                        } else {
                            System.showErrors(msg);
                            loaderForGoogleButton.stop();
                        }
                    }, isB2b);
                },*/
                callback: (tokenResponse) => handleGoogleTokenResponseCallback(tokenResponse, loaderForGoogleButton),
                error_callback: (error) => {
                    console.log(error);
                    loaderForGoogleButton.stop();
                    if (error.type === 'popup_closed') {
                        System.showErrors('Login has been cancelled');
                    } else {
                        System.showErrors(error.message);
                    }
                },
                // Request scopes in addition to 'profile' and 'email'
                scope: 'email' // https://www.googleapis.com/auth/userinfo.profile
            });

            $('.google-button-login, .google-button-sign, .js-login-on-google-plus, .js-login-from-goole').off('click').on('click', function (e) {
                loaderForGoogleButton = Ladda.create(this);
                loaderForGoogleButton.start();
                googleTokenClient.requestAccessToken();
            });
            //            var bLogin = document.getElementsByClassName('google-button-login');
            /*            var elements = $('.google-button-login, .google-button-sign, .js-login-on-google-plus, .js-login-from-goole'); // document.getElementsByClassName('google-button-sign');
                        if(elements.length>0){
                            attachSignin(elements);
                        }*/
        };
    };

    startApp();

    $('.signup-btn, .js-btn-signup, .signup-btn-popup, .js-join-b2c').off('click').on('click', function (e) {
        e.preventDefault();
        var email = this.form.email.value;
        var password = this.form.password.value;
        var terms = this.form.agree_terms.checked;

        if (!validateEmail($(this.form.email)) || !validatePassword($(this.form.password))) {
            //            return false;
        }
        if (!terms) {
            System.showErrors(TEXT_TO_USE_PP_AND_TOS);
            return;
        }

        //        console.log('next');
        //        validateServerEmail($(this.form.email));
        var me = this;
        var isBuy = $(this).data('isbuy');
        var isObt = $(this).data('obt');
        var usertype = $(this).attr('data-usertype');
        var l = Ladda.create(this);
        l.start();
        Auth.checkEmail(email, function (r, msg) {
            if (!r) {
                l.stop();
                System.showErrors(msg + ' <a href="#restore-password" onClick="System.hideErrors();$(\'.restore input[name=email]\').val(\'' + email + '\'); forgot_reg();">Forgot password?</a>');
            } else {
                if (isObt) {
                    Auth.registerObt(email, password, function (result, message) {
                        if (result) {
                            Auth.addTestBooks(function () {
                                l.stop();
                                me.form.email.value = '';
                                me.form.password.value = '';
                            });
                        } else {
                            System.showErrors(message);
                            $('.joinform .email-input').removeClass('success').addClass('error');
                            l.stop();
                        }
                    });
                } else {
                    Auth.register(email, password, null, function (result, message) {
                        if (result) {
                            MA_JoinSuccess(e, usertype);

                            Auth.login(email, password, function () {
                                if (isBuy) {
                                    cart[isBuy](me, l);
                                    Auth.initPage();
                                } else {
                                    // l.stop();
                                    location = "/user/profile";
                                }
                            });
                        } else {
                            System.showErrors(message);
                            $('.joinform .email-input').removeClass('success').addClass('error');
                            l.stop();
                        }
                    });
                }
            }
        }, false);
    });

    $('#change_btn, .change_btn, .js-restore-password').on('click', function (e) {
        e.preventDefault();
        var l = Ladda.create(this);
        l.start();

        var email = this.form.email.value;
        if (email.trim().length == 0) {
            System.showErrors(locale['TEXT_EMAIL_CANNOT_BE_EMPTY']);
            l.stop();
            return;
        }

        var res = Auth.restorePassword(email, function (res, message) {
            l.stop();
            if (res) {
                $('.form_placeholder').hide();
                System.notify(message);
                $('.login-reg').trigger('click');

                if ($("#join-window:visible").length != 0) {
                    cbShowLoginForm();
                }
            } else {
                System.showErrors(message);
            }
        });

        return false;
    });

    $('.js-join-b2b').unbind('click').on('click', function (e) {
        $('.alert-message').hide();
        e.preventDefault();

        var email = this.form.email;
        var password = this.form.password;

        var emailVal = this.form.email.value;
        var passwordVal = this.form.password.value;
        var terms = this.form.agree_terms.checked;

        if (!emailVal) {
            $(email).removeClass('success').addClass('error');
            addTooltipler($(email), locale['TEXT_EMAIL_CANNOT_BE_EMPTY']);
        }

        if (!passwordVal) {
            $(password).removeClass('success').addClass('error');
            addTooltipler($(password), 'Sorry, password cannot be empty.');
        }

        if (!emailVal) {
            System.showErrors(locale['TEXT_EMAIL_CANNOT_BE_EMPTY']);
            return;
        } else if (!validateEmail($(email))) {
            System.showErrors(TEXT_EMAIL_IS_INCORRECT);
        } else {

        }
        if (!passwordVal) {
            System.showErrors('Sorry, password cannot be empty.');
            return false;
        } else if (passwordVal.length < 6) {
            System.showErrors(TEXT_PASSWORDS_MIN_SYMBOLS);
            return false;
        } else if (passwordVal.length > 30) {
            System.showErrors(TEXT_PASSWORDS_MAX_SYMBOLS);
            return false;
        } else {

        }

        if (!terms) {
            System.showErrors(TEXT_TO_USE_PP_AND_TOS);
            return false;
        }

        if (
            validateEmail($(email))
            && validatePassword($(password))
            && terms
        ) {

            var l = Ladda.create(this);
            l.start();

            window.grecaptcha.execute(window.reCAPTCHA_site_key, { action: 'register' })
                .then(function (token) {
                    $.ajax({
                        url: '/api/v1/b2b/register',
                        type: 'POST',
                        dataType: "json",
                        data: { 'email': emailVal, 'password': passwordVal, 'g-recaptcha-response': token },
                        success: function (data) {
                            // grecaptcha.reset();
                            switch (data.status) {
                                case 'success':
                                    MA_JoinSuccess(event, 'b2b');
                                    if (data.data.redirect != undefined) {
                                        window.location = data.data.redirect;
                                        return false;
                                    }
                                    window.location = "/user/projects";
                                    break;
                                case 'error':
                                    show_alert(data.message, 'error');
                                    l.stop();
                                    break;
                                default:
                                    show_alert(locale['APP_ERROR_INVALID_PARAMETR'], 'error');
                            }
                            l.stop();
                        }
                    });

                });
        }
    });

    $('.js-login-b2b').unbind('click').on('click', function (e) {
        $('.alert-message').hide();
        e.preventDefault();
        var usertype = $(this).attr('data-usertype');
        var email = this.form.email.value;
        var password = this.form.password.value;
        var remember = this.form.remember.checked ? 1 : 0;

        if (!email) {
            $(this.form.email).removeClass('success').addClass('error');
            addTooltipler($(this.form.email), locale['TEXT_EMAIL_CANNOT_BE_EMPTY']);
        }

        if (!password) {
            $(this.form.password).removeClass('success').addClass('error');
            addTooltipler($(this.form.password), 'Sorry, password cannot be empty.');
        }

        if (!email) {
            System.showErrors(locale['TEXT_EMAIL_CANNOT_BE_EMPTY']);
            return;
        } else if (!validateEmail($(this.form.email))) {
            System.showErrors(TEXT_EMAIL_IS_INCORRECT);
        } else {

        }

        if (!password) {
            System.showErrors('Sorry, password cannot be empty.');
            return false;
        } else if (password.length < 6) {
            System.showErrors(TEXT_PASSWORDS_MIN_SYMBOLS);
            return false;
        } else if (password.length > 30) {
            System.showErrors(TEXT_PASSWORDS_MAX_SYMBOLS);
            return false;
        } else {

        }
        if (validateEmail($(this.form.email)) && validatePassword($(this.form.password))) {
            var l = Ladda.create(this);
            l.start();
            $.ajax({
                url: '/api/v1/b2b/register',
                type: 'POST',
                dataType: "json",
                data: { 'email': email, 'password': password, 'case': 'login', 'remember': remember },
                success: function (data) {
                    // console.log(data);
                    switch (data.status) {
                        case 'success':
                            MA_LoginSuccess(e, usertype);
                            if (data.data.redirect != undefined) {
                                window.location = data.data.redirect;
                                return false;
                            }
                            window.location = "/user/projects";

                            break;
                        case 'error':
                            show_alert(data.message, 'error');
                            l.stop();
                            break;
                        default:
                            show_alert(locale['APP_ERROR_INVALID_PARAMETR'], 'error');
                    }
                    l.stop();
                }
            });
        }
    });
});
function login_reg() {
    $('.signup').removeClass('select');
    $('.signin').addClass('select');
    $('.flip-container').addClass('show-back');
    $('.flip-container').find('.registration-rp').fadeOut('300');
    $('.flip-container').find('.login-rp').fadeIn('600');
    $('.for-login, .title-reg').hide();
    $('.title-for, .flip-container .restore').hide();
    $('.for-registration, .title-log').fadeIn();
    location.hash = '#signin';

}
function register_reg() {
    $(window).trigger('user.signUp');
    $('.flip-container').removeClass('show-back');
    $('.flip-container').find('.login-rp').fadeOut('300');
    $('.flip-container').find('.registration-rp').fadeIn('600');
    $('.for-registration, .title-log').hide();
    $('.title-for, .flip-container .restore').hide();
    $('.for-login, .title-reg').fadeIn();
    location.hash = '#signup';
    $('.signup').addClass('select');
    $('.signin').removeClass('select');
}
function forgot_reg() {
    $('.flip-container').removeClass('show-back');
    $('.flip-container').find('.login-rp').fadeOut('300');
    $('.flip-container').find('.registration-rp').fadeOut('300');
    $('.for-login, .title-reg').hide();
    $('.title-log').hide();

    $('.flip-container').find('.restore').fadeIn('600');
    $('.for-registration, .title-for').fadeIn();

    location.hash = '#restore-password';
    $('.signup').removeClass('select');
    $('.signin').removeClass('select');
}

function handleGoogleTokenResponseCallback(tokenResponse, loaderForGoogleButton) {
    var element = $('.js-login-from-goole');
    var isBuy = $(element).data('isbuy');
    var isObt = $(element).data('obt');
    var usertype = $(element).attr('data-usertype');
    var isB2b = usertype === 'b2b' ? true : null;
    var isStandardForm = $(element).data('ispopup');
    var needsToBeReloaded = $('.login-main-page').length || isB2b;
    var page = $('#page').val();

    Auth.googleLogin(tokenResponse.access_token, function (r, data, msg) {
        console.log('usertype ' + usertype);
        if (r) {
            MA_LoginGoogleEvent(element, data.isB2b, data.isNew);

            if (data.isNew) {
                MA_JoinSuccess(element, usertype);
                // MA_JoinSuccessFBPixel(element, usertype);
            } else {
                MA_LoginSuccess(element, usertype);
            }
            var isVote = $('.google-button-login').data('isvote');
            var isFollow = $('.google-button-login').data('isfollow');
            var channelId = $('.google-button-login').data('channel');
            if (isVote == 1) {
                Auth.initPage();
                $('.vote-btn').click();
                $('.c-popup').fadeOut();
                return true;
            }
            if (isFollow == 1) {
                Auth.initPage();
                $('.js-follow-channel[data-channel="' + channelId + '"]').click();
                $('.c-popup').fadeOut();
                return true;
            }

            if (isBuy) {
                cart[isBuy](element, loaderForGoogleButton);
                Auth.initPage();
            } else if (isObt) {
                // loaderForGoogleButton.stop();
                Auth.addTestBooks();
            } else if (page == 'channel_subscribe') {
                $('#channel_subscribe_method').submit();
            } else if (data.isNew) {
                RedirectHelper.setAction('System.notify("Your account has been successfully activated!");');
                location.href = Config.BASE_PATH + "/store";
            } else if (isStandardForm) {
                if (usertype === 'b2b' && data.isNew) {
                    MA_JoinSuccess(event, 'b2b');
                }
                $(".join-window").hide();
                if (needsToBeReloaded) document.location.reload();
                else Auth.initPage();
            } else {
                if (data.isB2b) {
                    location.href = data.hash ? data.hash : "/user/projects";
                } else {
                    location.href = "/user/profile";
                }
            }
        } else {
            System.showErrors(msg);
            loaderForGoogleButton.stop();
        }
    }, isB2b);
}
/*function attachSignin(elements) {
    $(elements).each(function(index, element){
        var isBuy = $(element).data('isbuy');
        var isObt = $(element).data('obt');
        var usertype = $(element).attr('data-usertype');
        var isB2b = usertype === 'b2b' ? true : null;
        var isStandardForm = $(element).data('ispopup');
        var needsToBeReloaded = $('.login-main-page').length || isB2b;
        var page = $('#page').val();
        $(element).off('click').on('click',function(e){
            var googleTokenClient = google.accounts.oauth2.initTokenClient({
                client_id: google_client_id,
                prompt: 'select_account',
                //cookiepolicy: 'single_host_origin',
                callback: (tokenResponse) => {
                    console.log(tokenResponse);
                    var l = Ladda.create(element);
                    l.start();
                    Auth.googleLogin(tokenResponse.access_token, function (r, data, msg) {
                        console.log('usertype ' + usertype);
                        if (r) {
                            MA_LoginGoogleEvent(element, data.isB2b, data.isNew);

                            if(data.isNew){
                                MA_JoinSuccess(element, usertype);
                                // MA_JoinSuccessFBPixel(element, usertype);
                            } else {
                                MA_LoginSuccess(element, usertype);
                            }
                            var isVote = $('.google-button-login').data('isvote');
                            var isFollow = $('.google-button-login').data('isfollow');
                            var channelId = $('.google-button-login').data('channel');
                            if(isVote == 1) {
                                Auth.initPage();
                                $('.vote-btn').click();
                                $('.c-popup').fadeOut();
                                return true;
                            }
                            if(isFollow == 1) {
                                Auth.initPage();
                                $('.js-follow-channel[data-channel="'+channelId+'"]').click();
                                $('.c-popup').fadeOut();
                                return true;
                            }

                            if(isBuy){
                                cart[isBuy](element, l);
                                Auth.initPage();
                            } else if(isObt) {
                                l.stop();
                                Auth.addTestBooks();
                            } else if (page == 'channel_subscribe') {
                                $('#channel_subscribe_method').submit();
                            } else if (data.isNew) {
                                RedirectHelper.setAction('System.notify("Your account has been successfully activated!");');
                                location.href = Config.BASE_PATH + "/store";
                            }else if (isStandardForm) {
                                if(usertype === 'b2b' && data.isNew){
                                    MA_JoinSuccess(event, 'b2b');
                                }
                                $(".join-window").hide();
                                if (needsToBeReloaded) document.location.reload();
                                else Auth.initPage();
                            } else {
                                if(data.isB2b) {
                                    location.href = data.hash ? data.hash :"/user/projects";
                                } else {
                                    location.href = "/user/profile";
                                }
                            }
                        } else {
                            System.showErrors(msg);
                            l.stop();
                        }
                    }, isB2b);
                },
                error_callback: (error) => {
                    console.log(error);
/!*                    if(error.error || !error.access_token) {
                        if (error.error_description=== 'popup_closed_by_user') {
                            System.showErrors('Login has been cancelled');
                        } else {
                            System.showErrors(error.error_description);
                        }
                    }*!/
                },
                // Request scopes in addition to 'profile' and 'email'
                scope: 'email' // https://www.googleapis.com/auth/userinfo.profile
            });
        });
        /!*            auth2.attachClickHandler(element, {},
                        function (googleUser) {
                            var l = Ladda.create(element);
                            l.start();
                            Auth.googleLogin(googleUser.getAuthResponse().access_token, function (r, data, msg) {
                                console.log('usertype ' + usertype);
                                if (r) {
                                    MA_LoginGoogleEvent(element, data.isB2b, data.isNew);

                                    if(data.isNew){
                                        MA_JoinSuccess(element, usertype);
                                        // MA_JoinSuccessFBPixel(element, usertype);
                                    } else {
                                        MA_LoginSuccess(element, usertype);
                                    }
                                    var isVote = $('.google-button-login').data('isvote');
                                    var isFollow = $('.google-button-login').data('isfollow');
                                    var channelId = $('.google-button-login').data('channel');
                                    if(isVote == 1) {
                                        Auth.initPage();
                                        $('.vote-btn').click();
                                        $('.c-popup').fadeOut();
                                        return true;
                                    }
                                    if(isFollow == 1) {
                                        Auth.initPage();
                                        $('.js-follow-channel[data-channel="'+channelId+'"]').click();
                                        $('.c-popup').fadeOut();
                                        return true;
                                    }

                                    if(isBuy){
                                        cart[isBuy](element, l);
                                        Auth.initPage();
                                    } else if(isObt) {
                                        l.stop();
                                        Auth.addTestBooks();
                                    } else if (page == 'channel_subscribe') {
                                        $('#channel_subscribe_method').submit();
                                    } else if (data.isNew) {
                                        RedirectHelper.setAction('System.notify("Your account has been successfully activated!");');
                                        location.href = Config.BASE_PATH + "/store";
                                    }else if (isStandardForm) {
                                        if(usertype === 'b2b' && data.isNew){
                                            MA_JoinSuccess(event, 'b2b');
                                        }
                                        $(".join-window").hide();
                                        if (needsToBeReloaded) document.location.reload();
                                        else Auth.initPage();
                                    } else {
                                        if(data.isB2b) {
                                            location.href = data.hash ? data.hash :"/user/projects";
                                        } else {
                                            location.href = "/user/profile";
                                        }
                                    }
                                } else {
                                    System.showErrors(msg);
                                    l.stop();
                                }
                            }, isB2b);
                        }, function (error) {
                            // console.log(error);
                            //toDo fix errors!!!
                            if (error.error === 'popup_closed_by_user') {
                                System.showErrors('Login has been cancelled');
                            } else {
                                System.showErrors(error.reason);
                            }
                        }
                    );*!/
    });
}*/

